import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import "./DevOps.css";

export default function DevOpsPage() {
  const sections = [
    {
      id: 'intro',
      title: 'Introduction',
      subsections: []
    },
    {
      id: 'linux',
      title: 'Linux Basics',
      subsections: [
        { id: 'linux-commands', title: 'Essential Commands' },
        { id: 'linux-best-practices', title: 'Best Practices' },
        { id: 'linux-management', title: 'System Management' },
        { id: 'linux-troubleshooting', title: 'Troubleshooting' }
      ]
    },
    {
      id: 'scripting',
      title: 'Scripting',
      subsections: [
        { id: 'scripting-python', title: 'Python Automation' },
        { id: 'scripting-bash', title: 'Bash Scripting' },
        { id: 'scripting-tools', title: 'Automation Tools' }
      ]
    },
    {
      id: 'networking',
      title: 'Networking',
      subsections: [
        { id: 'networking-basics', title: 'Basic Concepts' },
        { id: 'networking-protocols', title: 'Protocols' },
        { id: 'networking-security', title: 'Security' },
        { id: 'networking-cloud', title: 'Cloud Networking' }
      ]
    },
    {
      id: 'aws',
      title: 'AWS Cloud',
      subsections: [
        { id: 'aws-core', title: 'Core Services' },
        { id: 'aws-security', title: 'Security & IAM' },
        { id: 'aws-networking', title: 'Networking' },
        { id: 'aws-containers', title: 'Containers' }
      ]
    },
    {
      id: 'iac',
      title: 'Infrastructure as Code',
      subsections: [
        { id: 'iac-terraform', title: 'Terraform' },
        { id: 'iac-ansible', title: 'Ansible' },
        { id: 'iac-cloudformation', title: 'CloudFormation' }
      ]
    },
    {
      id: 'cicd',
      title: 'CI/CD',
      subsections: [
        { id: 'cicd-concepts', title: 'Core Concepts' },
        { id: 'cicd-github', title: 'GitHub Actions' },
        { id: 'cicd-jenkins', title: 'Jenkins' },
        { id: 'cicd-strategies', title: 'Deployment Strategies' }
      ]
    },
    {
      id: 'containers',
      title: 'Containers & Kubernetes',
      subsections: [
        { id: 'containers-docker', title: 'Docker' },
        { id: 'containers-k8s-basics', title: 'K8s Basics' },
        { id: 'containers-k8s-advanced', title: 'K8s Advanced' },
        { id: 'containers-helm', title: 'Helm' }
      ]
    },
    {
      id: 'gitops',
      title: 'GitOps',
      subsections: [
        { id: 'gitops-principles', title: 'Principles' },
        { id: 'gitops-argocd', title: 'ArgoCD' },
        { id: 'gitops-flux', title: 'Flux' }
      ]
    },
    {
      id: 'monitoring',
      title: 'Monitoring & Logging',
      subsections: [
        { id: 'monitoring-infra', title: 'Infrastructure' },
        { id: 'monitoring-apps', title: 'Applications' },
        { id: 'monitoring-alerts', title: 'Alerting' },
        { id: 'monitoring-dashboards', title: 'Dashboards' }
      ]
    },
    {
      id: 'resources',
      title: 'Additional Resources',
      subsections: [
        { id: 'resources-courses', title: 'Courses' },
        { id: 'resources-books', title: 'Books' },
        { id: 'resources-community', title: 'Community' }
      ]
    }
  ];

  const [expandedSection, setExpandedSection] = useState(null);
  const [visibleSubsection, setVisibleSubsection] = useState("intro");
  const [subsectionContent, setSubsectionContent] = useState({});

  useEffect(() => {
    // Fetch the welcome content when the page loads
    fetchSubsectionContent("intro");
  }, []);

  const toggleSection = (sectionId) => {
    setExpandedSection((prev) => (prev === sectionId ? null : sectionId));
  };

  const showSubsection = (subsectionId) => {
    setVisibleSubsection(subsectionId);
    fetchSubsectionContent(subsectionId);
  };

  const fetchSubsectionContent = async (subsectionId) => {
    try {
      const response = await fetch(
        `/content/devops-resources/${subsectionId}.md`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const text = await response.text();
      setSubsectionContent((prev) => ({ ...prev, [subsectionId]: text }));
    } catch (error) {
      console.error("Error fetching subsection content:", error);
      setSubsectionContent((prev) => ({
        ...prev,
        [subsectionId]: "Error loading content.",
      }));
    }
  };

  const isSectionExpanded = (sectionId) => expandedSection === sectionId;
  const isSubsectionVisible = (subsectionId) => visibleSubsection === subsectionId;

  return (
    <div className="docs-page">
      {/* Navigation Sidebar */}
      <nav className="docs-nav">
        <ul className="nav-links">
          {sections.map((section) => (
            <li key={section.id}>
              <div
                className="nav-link-main"
                onClick={(e) => {
                  e.preventDefault();
                  if (section.id === "intro") {
                    // Special handling for "Introduction"
                    showSubsection("intro");
                  } else {
                    toggleSection(section.id);
                  }
                }}
              >
                {section.title}
                {section.subsections.length > 0 && (
                  <span
                    className={`expand-icon ${
                      isSectionExpanded(section.id) ? "expanded" : ""
                    }`}
                  >
                    ▼
                  </span>
                )}
              </div>
              {section.subsections.length > 0 && (
                <ul
                  className={`nav-links-sub ${
                    isSectionExpanded(section.id) ? "expanded" : ""
                  }`}
                >
                  {section.subsections.map((subsection) => (
                    <li
                      key={subsection.id}
                      className="nav-link-sub"
                      onClick={() => showSubsection(subsection.id)}
                    >
                      {subsection.title}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>

      {/* Main Content */}
      <main className="docs-content">
        {visibleSubsection && (
          <section
            key={visibleSubsection}
            id={visibleSubsection}
            className="subsection"
          >
            <h3>
              {visibleSubsection === "intro"
                ? "Introduction"
                : sections
                    .flatMap((s) => s.subsections || [])
                    .find((ss) => ss.id === visibleSubsection)?.title}
            </h3>
            <ReactMarkdown
              children={subsectionContent[visibleSubsection] || "Comming Soon..."}
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({ children }) => <h1 className="markdown-heading">{children}</h1>,
                h2: ({ children }) => <h2 className="markdown-heading">{children}</h2>,
                h3: ({ children }) => <h3 className="markdown-heading">{children}</h3>,
                p: ({ children }) => <p className="markdown-paragraph">{children}</p>,
                ul: ({ children }) => <ul className="markdown-list">{children}</ul>,
                ol: ({ children }) => <ol className="markdown-list">{children}</ol>,
                li: ({ children }) => <li className="markdown-list-item">{children}</li>,
                code: ({ node, inline, className, children, ...props }) => {
                  const match = /language-(\w+)/.exec(className || "");
                  return !inline && match ? (
                    <SyntaxHighlighter style={docco} language={match[1]} {...props}>
                      {String(children).replace(/\n$/, "")}
                    </SyntaxHighlighter>
                  ) : (
                    <code className="inline-code" {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            />
          </section>
        )}
      </main>
    </div>
  );
}
import { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";

function Navbar() {
  const [navActive, setNavActive] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false); // New state for dropdown
  const navigate = useNavigate();
  const location = useLocation();

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
    setDropdownActive(false); // Close dropdown when menu closes
  };

  const handleBlogClick = () => {
    navigate("/blog");
    closeMenu();
  };

  const handleNavClick = async (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        scrollToSectionInPage(sectionId);
      }, 100);
    } else {
      scrollToSectionInPage(sectionId);
    }
    closeMenu();
  };

  const scrollToSectionInPage = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  };

  const handleDropdownClick = (path) => {
    navigate(path);
    closeMenu();
  };

  return (
    <nav className={`navbar ${navActive ? "active" : ""}`}>
      <div>
        <p id="logo--text">
          <ScrollLink
            onClick={() => handleNavClick("heroSection")}
            spy={true}
            smooth={true}
            duration={500}
            className="logo--text"
          >
            Branko Petric
          </ScrollLink>
        </p>
      </div>
      <a
        className={`nav__hamburger ${navActive ? "active" : ""}`}
        onClick={toggleNav}
      >
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
      </a>
      <div className={`navbar--items ${navActive ? "active" : ""}`}>
        <ul>
          <li>
            <a onClick={handleBlogClick} className="navbar--content">
              Blog
            </a>
          </li>
          <li>
            <ScrollLink
              onClick={() => handleNavClick("MyPortfolio")}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              duration={500}
              className="navbar--content"
            >
              Case Studies
            </ScrollLink>
          </li>
          {/* New Resources Dropdown */}
          <li
            className="navbar--content resources-dropdown"
            onMouseEnter={() => setDropdownActive(true)}
            onMouseLeave={() => setDropdownActive(false)}
          >
            Resources
            {dropdownActive && (
              <ul className="dropdown-menu">
                <li onClick={() => handleDropdownClick("/devops")}>
                  DevOps Guide
                </li>
                <li onClick={() => window.open("https://kubewhisper.com", "_blank")}>
                  KubeWhisper
                </li>
              </ul>
            )}
          </li>
          <li>
            <ScrollLink
              onClick={() => handleNavClick("testimonial")}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              duration={500}
              className="navbar--content"
            >
              Testimonials
            </ScrollLink>
          </li>
        </ul>
      </div>
      <ScrollLink
        onClick={() => handleNavClick("Contact")}
        activeClass="navbar--active-content"
        spy={true}
        smooth={true}
        duration={500}
        className="btn btn-outline-primary"
      >
        Contact Me
      </ScrollLink>
    </nav>
  );
}

export default Navbar;